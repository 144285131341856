<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional v-model:value="region" />
        <filter-province class="ml-2" v-model:value="province" v-model:region="region" />
        <filter-area
          class="ml-2"
          v-model:value="area"
          v-model:area="area"
          v-model:province="province"
          v-model:region="region"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <filter-district
          class="mr-2"
          v-model:value="district"
          v-model:region="region"
          v-model:area="area"
          v-model:province="province"
        />
        <a-select
          :options="[
            { value: 1, label: 'Aktif' },
            { value: 0, label: 'Tidak Aktif' },
          ]"
          :mode="null"
          allow-clear
          class="mt-2"
          style="width: 300px;"
          placeholder="Pilih Status"
          v-model:value="active"
        >
        </a-select>
        <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="m-2 mt-2"
        />
        <a-button
          class="m-2 mt-2"
          type="primary"
          title="cari"
          @click="handleSearchTable()"
          :loading="loading"
        >
          <i class="fa fa-search" aria-hidden="true" v-if="!loading"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-12 d-flex justify-content-end">
        <a-button @click="handleActivatedData('aktivasi')" title="Aktivasi Data" type="primary">
          <i class="fa fa-check" aria-hidden="true"></i>
        </a-button>
        <a-button
          class="ml-2 mr-2"
          title="Non Aktif Data"
          @click="handleActivatedData('nonaktif')"
          type="danger"
        >
          <i class="fa fa-ban" aria-hidden="true"></i>
        </a-button>

        <a-button title="Tambah SPSI" @click="handleOpenModalSPSI()" type="primary">
          <i class="fa fa-plus" aria-hidden="true"></i>
        </a-button>

        <a-button class="ml-2" title="Download Excel" type="primary" :loading="isDownload" @click="fetchXlsx">
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownload"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive text-nowrap mt-2">
          <md-table
            size="small"
            row-key="id"
            :columns="columns"
            :data-source="dataTable"
            :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: handleSelectChange }"
            :loading="loading"
            :pagination="{
              showSizeChanger: true,
              pageSizeOptions: pageSizeOptionData,
              total: meta.totalCount,
              pageSize: meta.perPage,
              current: meta.currentPage,
              showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
            }"
            @change="handleTableChange"
          >
            <template #no="{ index }">
              <span>
                {{ startRow + index }}
              </span>
            </template>
            <template #status="{ text }">
              <render-active :value="text.active"></render-active>
            </template>
            <template #action="{ text }">
              <span>
                <a-tooltip title="Lihat SPSI">
                  <a
                    href="javascript:void(0)"
                    @click="handleDetailModalSPSI(text)"
                    class="btn btn-sm btn-light mr-1"
                  >
                    <small>
                      <i class="fe fe-eye" />
                    </small>
                  </a>
                </a-tooltip>
                <a-tooltip title="Ubah SPSI">
                  <a
                    href="javascript:void(0)"
                    @click="handleEditModalSPSI(text)"
                    class="btn btn-sm btn-light"
                  >
                    <small>
                      <i class="fe fe-edit" />
                    </small>
                  </a>
                </a-tooltip>
              </span>
            </template>
          </md-table>
        </div>
      </div>
    </div>

    <a-modal
      @cancel="state.showModal = false"
      :visible="state.showModal"
      destroy-on-close
      :width="state.width"
      :mask-closable="false"
      :title="state.title"
      :footer="null"
    >
      <a-form
        :model="formData"
        class="myform"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 18 }"
        ref="formRef"
      >
        <a-form-item required label="Kode SPSI" class="mb-0" label-align="left" name="code">
          <div class="d-flex align-items-center">
            <a-input
              v-model:value="formData.code"
              required
              type="text"
              maxlength="9"
              :disabled="
                state.valueForm === 'new' ? false : state.valueForm === 'edit' ? true : true
              "
            ></a-input>
            <a-button
              key="submit"
              :disabled="
                state.valueForm === 'new' ? false : state.valueForm === 'edit' ? true : true
              "
              type="primary"
              class="ml-2"
              @click="handleGenerateSPSICode()"
            >
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </a-button>
          </div>
          <span v-if="codeErrorMessage" class="text-danger">{{ codeErrorMessage }}</span>
        </a-form-item>
        <a-form-item class="mb-0" label="Tanggal Registrasi" label-align="left" name="registration_at" required>
          <a-date-picker
            v-model:value="formData.registration_at"
            placeholder="Tanggal Registrasi"
            value-format="YYYY-MM-DD"
            required
            :disabled="state.readOnly"
            :disabled-date="disabledMaxDate"
          />
        </a-form-item>
        <a-form-item name="name" required label="Nama SPSI" class="mb-0" label-align="left">
          <a-input
            type="text"
            v-model:value="formData.name"
            required
            :disabled="state.readOnly"
          ></a-input>
        </a-form-item>
        <a-form-item label="Nomor Telp" class="mb-0" required name="phone" label-align="left">
          <a-input
            v-model:value="formData.phone"
            :disabled="state.readOnly"
            addon-before="+62"
            placeholder="Ex : 81xxxxxxxxx"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            pattern="^[1-9][0-9]*$"
            required
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
            _type="text"
            maxlength="12"
          />
        </a-form-item>
        <a-form-item label="Alamat" class="mb-0" required name="address" label-align="left">
          <a-input
            placeholder="Alamat"
            v-model:value="formData.address"
            :disabled="state.readOnly"
          />
        </a-form-item>
        <a-form-item
          label="Kabupaten/Kota"
          class="mb-0"
          required
          name="wilayah_id"
          label-align="left"
        >
          <filter-district-all
            v-model:value="formData.wilayah_id"
            label="fullname"
            :disabled="state.readOnly"
            :join-options="
              formData.wilayah_id ? [{ id: formData.wilayah_id, fullname: formData.wilayah }] : []
            "
          />
        </a-form-item>
        <a-form-item label="Status" label-align="left" required name="active">
          <a-input
            v-if="state.readOnly"
            v-model:value="formData.active"
            :disabled="state.readOnly"
          ></a-input>
          <a-radio-group v-else v-model:value="formData.active">
            <a-radio :value="true">Aktif</a-radio>
            <a-radio :value="false">Non Aktif</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>

      <a-row class="form-row mt-5 justify-content-end">
        <a-col>
          <a-button
            :loading="loading"
            key="submit"
            type="primary"
            @click="handleSubmitForm()"
            :hidden="state.readOnly"
            >Simpan
          </a-button>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterDistrictAll from '@/components/filter/FilterWilayahSemua'
import FilterDistrict from '@/components/filter/FilterKabupatenKota'
import apiClient from '@/services/axios'
import rules from './data/rules'
import moment from 'moment'

import { columns } from './data/columns'
import { data } from './data/dataTable'
import { RenderActive } from '@/components'
import { ref, reactive, onMounted, toRefs, computed } from 'vue'
import { Modal, message } from 'ant-design-vue'
import qs from 'qs'

export default {
  name: 'MasterSPSI',
  components: {
    FilterRegional,
    FilterProvince,
    FilterDistrict,
    FilterDistrictAll,
    FilterArea,
  },
  setup() {
    const perPage = ref(10)
    const startRow = ref(1)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const offset = ref(0)
    const dataTable = ref([])
    const dataExport = ref([])
    const meta = ref({})
    const page = ref(1)
    const region = ref([])
    const province = ref([])
    const district = ref([])
    const area = ref([])
    const pageSizeOptionData = ref(['5', '10', '20', '30', '50', '75'])
    const q = ref('')
    const active = ref(null)
    const loading = ref(false)
    const exportDataValidation = ref(true)
    const errorMessage = ref(null)
    const paramsFilter = ref({})
    const generateNumber = ref(1)
    const param = ref({})
    const formData = ref({
      address: '',
      name: '',
      code: null,
      phone: null,
      wilayah_id: null,
      wilayah: '',
      registration_at: null,
      active: true,
      id: null,
    })
    const formRef = ref('')
    const state = reactive({
      selectedRowKeys: [],
      title: '',
      showModal: false,
      readOnly: false,
      width: '900px',
      valueForm: '',
      isDownload: false,
    })
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
      },
      onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows)
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows)
      },
    }

    const currentDate = computed(() => currentDate.value)
    const disabledMaxDate = date => {
      if (!date || !moment(currentDate.value)) {
        return false
      }
      return date.valueOf() > moment(currentDate.value).valueOf()
    }

    onMounted(() => {
      fetchData()
    })

    const fetchData = () => {
      loading.value = true
      const params = {
        page: page.value,
        region: region.value,
        area: area.value,
        provinsi: province.value,
        kabupaten: district.value,
        active: active.value,
        q: q.value,
      }
      param.value = Object.assign({}, params)
      apiClient
        .get('/api/spsi', { params: params })
        .then(response => {
          if (response === undefined) errorMessage.value = `Kode error 500, No response from server`

          const { items, _meta } = response.data

          dataTable.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = error.response.data.message.substring(0, 150)
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const fetchXlsx = () => {
      state.isDownload = true
      apiClient
        .get('/api/spsi', {
          params: {
            ...param.value,
            data_id: state.selectedRowKeys,
            _export: 'xls',
            _columns: columns
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `master-data-spsi_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownload = false
        })
    }

    const handleSearchTable = () => {
      fetchData()
    }

    const handleShowDelete = () => {
      if (state.selectedRowKeys.length === 0) {
        Modal.warning({
          content: 'Checklist data yang akan di hapus!',
        })
      }
      if (state.selectedRowKeys.length > 0) {
        Modal.confirm({
          title: 'Konfirmasi hapus data',
          content: `Anda yakin ingin hapus (${state.selectedRowKeys.length}) data terpilih?`,
          onOk() {
            loading.value = true
            state.selectedRowKeys.forEach(itemId => {
              apiClient
                .delete(`/api/spsi/${itemId}`)
                .then(response => {
                  message.success('Berhasil dihapus')
                  loading.value = false
                  state.selectedRowKeys = []
                  fetchData()
                })
                .catch(error => {
                  if (error.response && error.response.data) {
                    message.error(error.response.data[0].message)
                  }
                })
                .finally(() => {})
            })
          },
          onCancel() {},
        })
      }
    }

    const handleTableChange = pg => {
      page.value = pg.current
      perPage.value = pg.pageSize
      startRow.value = (page.value - 1) * perPage.value + 1
      fetchData()
    }

    const handleSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }

    const handleOpenModalSPSI = () => {
      state.title = 'Tambah Data SPSI'
      state.showModal = true
      state.valueForm = 'new'
      state.readOnly = false
      handleRefreshFormData()
    }

    const handleRefreshFormData = () => {
      formData.value.code = null
      formData.value.name = ''
      formData.value.address = ''
      formData.value.phone = null
      formData.value.registration_at = null
      formData.value.wilayah = null
      formData.value.wilayah_id = null
      formData.value.id = null
      formData.value.active = true
    }

    const handleDetailModalSPSI = items => {
      state.title = 'Detail Data SPSI'
      state.showModal = true
      state.valueForm = 'detail'
      state.readOnly = true
      formData.value.code = items.code
      formData.value.name = items.name
      formData.value.address = items.address
      formData.value.phone = items.phone
      formData.value.registration_at = items.registration_date
      formData.value.wilayah = items.wilayah
      formData.value.wilayah_id = items.wilayah_id
      formData.value.id = items.id
      formData.value.active = items.active === true ? 'Aktif' : 'Non Aktif'
    }

    const handleEditModalSPSI = items => {
      state.title = 'Edit Data SPSI'
      state.showModal = true
      state.valueForm = 'edit'
      state.readOnly = false
      formData.value.code = items.code
      formData.value.name = items.name
      formData.value.address = items.address
      formData.value.phone = items.phone
      formData.value.registration_at = items.registration_date
      formData.value.wilayah = items.wilayah
      formData.value.wilayah_id = items.wilayah_id
      formData.value.id = items.id
      formData.value.active = items.active
    }

    const handleGenerateSPSICode = () => {
      let numberString = generateNumber.value.toString().padStart(5, '0')
      let result = 'SPSI' + numberString

      generateNumber.value++
      formData.value.code = result
    }

    const handleSubmitForm = async () => {
      try {
        await formRef.value.validate()
      } catch (error) {
        console.error('submit', error)
        return
      }

      const formDatas = {
        code: formData.value.code,
        name: formData.value.name,
        phone: formData.value.phone,
        address: formData.value.address,
        wilayah_id: formData.value.wilayah_id,
        registration_at: formData.value.registration_at,
        active: formData.value.active === true ? 1 : 0,
      }
      if (state.valueForm === 'new') apiAddData(formDatas)
      if (state.valueForm === 'edit') apiEditData(formData.value.id, formDatas)
    }

    const handleActivatedData = async action => {
      if (state.selectedRowKeys.length === 0) {
        Modal.warning({
          content: 'Checklist data yang akan di aktivasi!',
        })
      }
      if (state.selectedRowKeys.length > 0) {
        if (action === `aktivasi`) apiActivatedData()
        if (action === `nonaktif`) apiUnActivatedData()
      }
    }

    const apiAddData = formData => {
      loading.value = false
      apiClient
        .post('/api/spsi', formData)
        .then(response => {
          loading.value = true
          message.success('Berhasil ditambah')
          state.showModal = false
          fetchData()
        })
        .catch(({ response: { data, status } }) => {
          if (status === 422) data[0].message
          if (status !== 422) data.message = 'Gagal menyimpan data! cek data kembali'
          Modal.error({
            title: 'Gagal Simpan',
            content: data[0].message,
            onOk() {
              console.log('ok')
            },
          })
        })
        .finally(() => (loading.value = false))
    }

    const apiEditData = (id, formData) => {
      loading.value = false
      apiClient
        .put(`/api/spsi/${id}`, formData)
        .then(response => {
          loading.value = true
          message.success('Berhasil diubah')
          state.showModal = false
          fetchData()
        })
        .catch(({ response: { data, status } }) => {
          if (status === 422) data[0].message
          if (status !== 422) data.message = 'Gagal mengubah data! cek data kembali'
          Modal.error({
            title: 'Gagal Ubah',
            content: data[0].message,
            onOk() {
              console.log('ok')
            },
          })
        })
        .finally(() => (loading.value = false))
    }

    const apiActivatedData = () => {
      const formData = {
        data_id: state.selectedRowKeys,
        active: 1,
      }

      Modal.confirm({
        title: 'Konfirmasi Aktifasi Data',
        content: 'Apakah anda ingin melanjutkan Ajukan Aktifasi ?',
        onOk() {
          loading.value = false
          apiClient
            .post('/api/spsi/set-active', formData)
            .then(response => {
              loading.value = true
              message.success('Berhasil di Aktifkan')
              state.showModal = false
              fetchData()
            })
            .catch(({ response: { data, status } }) => {
              if (status === 422) data[0].message
              if (status !== 422) data.message = 'Gagal aktifasi data! cek data kembali'
              Modal.error({
                title: 'Gagal Aktifasi',
                content: data[0].message,
                onOk() {
                  console.log('ok')
                },
              })
            })
            .finally(() => (loading.value = false))
        },
        onCancel() {},
      })
    }

    const apiUnActivatedData = () => {
      const formData = {
        data_id: state.selectedRowKeys,
        active: 0,
      }

      Modal.confirm({
        title: 'Konfirmasi Non Aktif Data',
        content: 'Apakah anda ingin melanjutkan Ajukan Non Aktif ?',
        onOk() {
          loading.value = false
          apiClient
            .post('/api/spsi/set-active', formData)
            .then(response => {
              loading.value = true
              message.success('Berhasil di Non Aktifkan')
              state.showModal = false
              fetchData()
            })
            .catch(({ response: { data, status } }) => {
              if (status === 422) data[0].message
              if (status !== 422) data.message = 'Gagal non aktif data! cek data kembali'
              Modal.error({
                title: 'Gagal Non Aktif',
                content: data[0].message,
                onOk() {
                  console.log('ok')
                },
              })
            })
            .finally(() => (loading.value = false))
        },
        onCancel() {},
      })
    }

    return {
      dataExport,
      offset,
      startRow,
      perPage,
      pageCount,
      pageSizeOptionData,
      rowSelection,
      totalCount,
      exportDataValidation,
      dataTable,
      meta,
      page,
      loading,
      columns,
      data,
      RenderActive,
      region,
      province,
      district,
      area,
      active,
      paramsFilter,
      errorMessage,
      q,
      state,
      rules,
      formData,
      formRef,
      generateNumber,
      ...toRefs(state),
      fetchData,
      fetchXlsx,
      handleOpenModalSPSI,
      handleShowDelete,
      handleTableChange,
      handleSelectChange,
      handleSearchTable,
      handleSubmitForm,
      handleGenerateSPSICode,
      handleActivatedData,
      handleDetailModalSPSI,
      handleEditModalSPSI,
      disabledMaxDate,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
